import React from "react"
import styles from "./blockTwoImagesText.module.css"

const BlockTwoImagesText = ({ bgColor, data }) => {
  const {
    title,
    text,
    imgTop: {
      uri: { url: imgTop },
    },
    imgBottom: {
      uri: { url: imgBottom },
    },
  } = data
  return (
    <section className={styles.blockTwoImagesText}>
      <div className={styles.blockTwoImagesText__imgUp}>
        <img src={`https://drupal.smartempo.com${imgTop}`} alt={title} />
      </div>
      <div className={styles.blockTwoImagesText__titleText}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <div className={styles.blockTwoImagesText__imgDown}>
        <img src={`https://drupal.smartempo.com${imgBottom}`} alt={title} />
      </div>
    </section>
  )
}
export default BlockTwoImagesText
