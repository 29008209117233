import React from "react"
import styles from "./blockThreeElements.module.css"

const BlockThreeElements = ({ bgColor, data }) => {
  const {
    boxTitle,
    boxText,
    boxImg: {
      uri: { url: image },
    },
    text,
  } = data
  return (
    <section className={styles.blockThreeElements}>
      <div className={styles.blockThreeElements__bgBlack} />
      <div className={styles.blockThreeElements__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={boxTitle} />
      </div>
      <div className={styles.blockThreeElements__text}>
        <p>{text}</p>
      </div>
      <div className={styles.blockThreeElements__tilteText}>
        <h3>{boxTitle}</h3>
        <p>{boxText}</p>
      </div>
    </section>
  )
}
export default BlockThreeElements
