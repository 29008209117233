import React from "react"
import styles from "./blockTextSmallImg.module.css"

const BlockTextSmallImg = ({ bgColor, data }) => {
  const {
    text,
    img: {
      uri: { url: image },
    },
  } = data
  return (
    <section className={styles.blockTextSmallImg}>
      <div className={styles.blockTextSmallImg__text}>
        <p>{text}</p>
      </div>
      <div className={styles.blockTextSmallImg__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={text} />
      </div>
    </section>
  )
}
export default BlockTextSmallImg
