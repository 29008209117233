import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockFiveElements from "../components/BlockFiveElements/BlockFiveElements"
import BlockTextCenter from "../components/BlockTextCenter/BlockTextCenter"
import BlockTwoImagesText from "../components/BlockTwoImagesText/BlockTwoImagesText"
import BlockTwoColumns from "../components/BlockTwoColumns/BlockTwoColumns"
import BlockThreeElements from "../components/BlockThreeElements/BlockThreeElements"
import BlockImgLeftFloatingText from "../components/BlockImgLeftFloatingText/BlockImgLeftFloatingText"
import BlockImgRightFloatingText from "../components/BlockImgRightFloatingText/BlockImgRightFloatingText"
import BlockTextSmallImg from "../components/BlockTextSmallImg/BlockTextSmallImg"

import Footer from "../components/Footer/Footer"

import { useDevelopmentPage } from "../hooks/use-developmentPage"

const Development = () => {
  const developmentData = useDevelopmentPage()
  const fiveElemsData = {
    boxText: developmentData.field_five_elements_box_text_,
    boxTitle: developmentData.field_five_elements_box_title_,
    boxImg: developmentData.relationships.field_five_elements_box_img_,
    title: developmentData.field_five_elements_title_,
    text: developmentData.field_five_elements_text_,
    img: developmentData.relationships.field_five_elements_bgimg_,
  }
  const textCenter1 = {
    title: developmentData.field_text_center_title_,
    text: developmentData.field_text_center_text_.processed,
  }
  const textCenter2 = {
    title: developmentData.field_text_center2_title_,
    text: developmentData.field_text_center2_text_.processed,
  }
  const twoImagesTextData = {
    title: developmentData.field_two_img_text_title_,
    text: developmentData.field_two_img_text_text_,
    imgTop: developmentData.relationships.field_two_img_text_imgtop_,
    imgBottom: developmentData.relationships.field_two_img_text_imgbottom_,
  }
  const twoColumnsData = {
    titleOne: "",
    textOne: developmentData.field_two_columns_col_1_text_,
    titleTwo: "",
    textTwo: developmentData.field_two_columns_col_2_text_,
  }
  const threeElemsData = {
    boxText: developmentData.field_three_elements_box_text_,
    boxTitle: developmentData.field_three_elements_box_title_,
    boxImg: developmentData.relationships.field_three_elements_img_,
    text: developmentData.field_three_elements_text_,
  }
  const imgLeftFloatingTextData = {
    title: developmentData.field_imgleftfloatingtext_title_,
    text: developmentData.field_imgleftfloatingtext_text_,
    img: developmentData.relationships.field_imgleftfloatingtext_img_,
  }
  const imgRightFloatingTextData = {
    title: developmentData.field_imgrightfloatingtext_title,
    text: developmentData.field_imgrightfloatingtext_text_,
    img: developmentData.relationships.field_imgrightfloatingtext_img_,
  }
  const textSmallImgData = {
    text: developmentData.field_imgrightfloatingtext_text_,
    img: developmentData.relationships.field_imgrightfloatingtext_img_,
  }

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockFiveElements data={fiveElemsData} />
        <BlockTextCenter titleColor="font-red-dark" data={textCenter1} />
        <BlockTwoImagesText data={twoImagesTextData} />
        <BlockTwoColumns data={twoColumnsData} />
        <BlockThreeElements data={threeElemsData} />
        <BlockImgLeftFloatingText data={imgLeftFloatingTextData} />
        <BlockImgRightFloatingText data={imgRightFloatingTextData} />
        <BlockTextCenter data={textCenter2} />
        <div style={{ textAlign: "center" }}>
          <iframe
            src="https://player.vimeo.com/video/257014232?title=0&byline=0&portrait=0"
            width="640"
            height="360"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          />
        </div>
        {/* <BlockTextSmallImg data={textSmallImgData} /> */}
        <Footer />
      </div>
    </div>
  )
}

export default Development
