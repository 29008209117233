import React from "react"
import styles from "./blockFiveElements.module.css"
const BlockFiveElements = ({ bgColor, data }) => {
  const {
    title,
    text,
    img: {
      uri: { url: bgImg },
    },
    boxText,
    boxTitle,
    boxImg: {
      uri: { url: boxImg },
    },
  } = data
  return (
    <section className={styles.blockFiveElements}>
      <div
        className={styles.blockFiveElements__bgImg}
        style={{
          backgroundImage: `url(https://drupal.smartempo.com${bgImg})`,
        }}
      />
      <div className={styles.blockFiveElements__bigText}>
        <p>{title}</p>
      </div>
      <div className={styles.blockFiveElements__text}>
        <p>{text}</p>
      </div>
      <div className={styles.blockFiveElements__img}>
        <img src={`https://drupal.smartempo.com${boxImg}`} alt={title} />
      </div>
      <div className={styles.blockFiveElements__titleText}>
        <h3>{boxTitle}</h3>
        <p>{boxText}</p>
      </div>
    </section>
  )
}
export default BlockFiveElements
