import { useStaticQuery, graphql } from "gatsby"

export const useDevelopmentPage = () => {
  const { allNodeDevelopmentPage } = useStaticQuery(
    graphql`
      query DevelopmentPage {
        allNodeDevelopmentPage {
          edges {
            node {
              field_five_elements_box_text_
              field_five_elements_box_title_
              field_five_elements_text_
              field_five_elements_title_
              field_imgleftfloatingtext_text_
              field_imgleftfloatingtext_title_
              field_imgrightfloatingtext_text_
              field_imgrightfloatingtext_title
              field_text_center2_text_ {
                processed
              }
              field_text_center2_title_
              field_text_center_text_ {
                processed
              }
              field_text_center_title_
              field_three_elements_box_text_
              field_three_elements_box_title_

              field_two_columns_col_1_text_ {
                processed
              }
              field_two_columns_col_1_title_
              field_two_columns_col_2_text_ {
                processed
              }
              field_two_columns_col_2_title_
              field_two_img_text_text_
              field_two_img_text_title_
              relationships {
                field_five_elements_bgimg_ {
                  uri {
                    url
                  }
                }
                field_five_elements_box_img_ {
                  uri {
                    url
                  }
                }
                field_imgleftfloatingtext_img_ {
                  uri {
                    url
                  }
                }
                field_imgrightfloatingtext_img_ {
                  uri {
                    url
                  }
                }
                field_three_elements_img_ {
                  uri {
                    url
                  }
                }
                field_two_img_text_imgbottom_ {
                  uri {
                    url
                  }
                }
                field_two_img_text_imgtop_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeDevelopmentPage.edges[0].node
}
