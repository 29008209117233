import React from "react"
import styles from "./blockTextCenter.module.css"
const BlockTextCenter = ({ bgColor, titleColor, data }) => {
  const { title, text } = data
  return (
    <section className={styles.blockTextCenter}>
      <div className={styles.blockTextCenter__text}>
        <h3 className={titleColor}>{title}</h3>
        <div
          className={styles.blockTextCenter__text__p}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </section>
  )
}
export default BlockTextCenter
